<script>
import {defineComponent, reactive} from "vue";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import {useI18n} from "vue-i18n";
import { useMeta } from "vue-meta";
import { downloadPayments } from "@/services/payments";
import { useApiCall } from "@/composables/useApiCall";

export default defineComponent({
  layout: {name: "AdminLayout"},
  components: {
    Button,
    Calendar,
  },
  setup() {
    const {t} = useI18n();
    useMeta({
      title: t('menu.payments')
    });

    const filters = reactive({
      dateFrom: null,
      dateTo: null,
      organization: null,
      clients: null,
      rentNumber: null,
      spaceNumber: null,
    })

    const { data: paymentsData, isLoading: isPaymentsLoading, executeApiCall: downloadPaymentsAction } = useApiCall(downloadPayments, true)

    const onDownloadPayments = async () => {
      const body = {
        fromDate: filters.dateFrom ?new Date(filters.dateFrom).toLocaleDateString('ru-RU', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }) : '',
        toDate: filters.dateTo ? new Date(filters.dateTo).toLocaleDateString('ru-RU', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }) : '',
        // organization: filters.organization,
        // clients: filters.clients,
        // rentNumber: filters.rentNumber,
      }
      try {
        await downloadPaymentsAction(body)
        const blob = paymentsData.value
        if (blob) {
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none'
          a.href = url;
          document.body.appendChild(a)
          a.download = 'payments.xlsx';
          a.click();
          URL.revokeObjectURL(url);
          document.body.removeChild(a)
        }
      } catch (error) {
        console.error(error)
      }
    }

    return { t, filters, isPaymentsLoading, onDownloadPayments };
  }
});
</script>

<template>
  <section class="py-2">
      <h1 class="mb-3">{{ t('menu.payments') }}</h1>
      <div class="p-card p-component p-3 mb-3 flex flex-column gap-2">
        <div class="font-bold">Период</div>
        <div class="flex gap-2 align-items-center">
          <span class="p-float-label w-3">
            <Calendar 
            v-model="filters.dateFrom"
            date-format="dd.mm.yy"
            placeholder="Дата с"
            :max-date="filters.dateTo"
            showButtonBar
            class="w-full" />
            <i class="pi pi-calendar calendar-icon" />
          </span>
          -
          <span class="p-float-label w-3">
            <Calendar 
              v-model="filters.dateTo"
              date-format="dd.mm.yy"
              placeholder="Дата до"
              :min-date="filters.dateFrom"
              showButtonBar
              class="w-full" />
            <i class="pi pi-calendar calendar-icon" />
          </span>
        </div>
      </div>
      <Button :label="t('payments.download')" :disabled="isPaymentsLoading" disabled @click="onDownloadPayments" class="btn-primary font-light" />
  </section>

</template>

<style scoped>
.p-card {
  border-radius: 8px;
  box-shadow: none;
}
::v-deep(.calendar-icon) {
  position: absolute;
  top: 9px;
  right: 10px;
  pointer-events: none;
  cursor: pointer;
}
</style>