<script>
import WebsiteCard from "@/components/cards/WebsiteCard.vue";
import {defineComponent, onBeforeMount, watch, ref} from "vue";
import Button from "primevue/button";
import ButtonSuccess from "@/components/buttons/ButtonSuccess.vue";
import WebsiteAddButton from "@/components/website/WebsiteAddButton.vue";
import {useI18n} from "vue-i18n";
import Skeleton from "primevue/skeleton"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload.vue";
import Textarea from "primevue/textarea";

export default defineComponent({
  components: {
    Textarea, ButtonFileUpload,
    ConfirmationModal,
    ButtonSuccess,
    WebsiteCard,
    Button,
    Skeleton,
    WebsiteAddButton,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    }
  },
  emits: ['deleteSlide', 'updateCaption'],
  setup(props, {emit}) {
    const caption = ref('')
    const {t} = useI18n()
    const deleteSlide = () => {
      emit('deleteSlide', props.item)
    }
    watch(() => caption.value, () => {
      emit('updateCaption', props.item, caption.value)
    })
    onBeforeMount(() => {
      caption.value = props.item.caption
    })
    return {
      t,
      caption,
      deleteSlide,
    };
  }
})
</script>

<template>
  <div class="infrastructure-image">
      <div class="flex justify-content-between">
        <h3>Слайд {{index + 1}}</h3>
        <i @click="deleteSlide" class="pi pi-times cansel-button cursor-pointer" style="font-size: 1rem"></i>
      </div>
      <div class="w-full mt-4">
          <span class="p-float-label w-full">
            <Textarea
                v-model="caption"
                class="w-full p-inputtext"
                :rows="2"
                id="caption"
            />
            <label for="title">Подпись к фото (не обязательно)</label>
          </span>
      </div>
      <div class="flex flex-column mt-2 gap-2">
        <h3>Изображение <i style="color: #E26B6B">*</i></h3>
<!--        <span  v-if="v$.photo.$errors.length || errors?.photo" class="text-xs color-error">-->
<!--              {{ errors?.photo ? errors.photo[0] : t(v$.photo.$errors[0].$message) }}-->
<!--        </span>-->
          <div>
            <img width="226" height="226" :src="item.preview_url ? item.preview_url : item.file.objectURL" />
          </div>
      </div>
  </div>
</template>

<style lang="scss" scoped>
.p-card {
  border-radius: 8px;
  box-shadow: none;
}

.p-card-title {
  font-size: 3rem !important;
}
.infrastructure-image {
  background: white;
  padding: 20px;
  border-radius: 10px;
}
</style>