<script>
import {computed, defineComponent, onMounted, ref, unref, watch} from "vue";
import {useStore} from "vuex";
import {usePartners} from "@/hooks/partners";

import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Editor from "primevue/editor";
import MainCard from "@/components/cards/MainCard";
import Breadcrumb from "@/components/Breadcrumb";
import ButtonSuccess from "@/components/buttons/ButtonSuccess";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload.vue";
import ImageCard from "@/components/cards/ImageCard.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import {useI18n} from "vue-i18n";
import {useRoute, useRouter} from "vue-router";

export default defineComponent({
  layout: {name: 'AdminLayout'},
  components: {
    ButtonSuccess,
    Button,
    InputText,
    MainCard,
    Dropdown,
    Breadcrumb,
    Editor,
    ButtonFileUpload,
    ImageCard,
    ConfirmationModal,
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const {loadPartner} = usePartners();
      await loadPartner(to.params.id);
    } catch (e) {
      console.log(e);
    }

    next();
  },
  setup() {
    const {
      partner,
      form,
      files,
      updatePartner,
      loadFileMemory,
      loadIconMemory,
      isUpdate,
      v$,
      destroyFileMemory,
      destroyFile,
      destroyPartner,
      deleteIcon,
    } = usePartners();
    const {t} = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter()
    const errors = ref(null)
    form.value = unref(partner);

    const visibleModal = ref(false);
    const partnerCategories = computed(() => store.getters.getListPartnerCategories);

    const promoTypes = ref([
      {label: 'QR-code', promo: 'qr'},
      {label: 'Промокод', promo: 'promocode'}
    ]);

    const breadcrumbs = ref([]);

    const loadPartnerCategories = async () => {
      await store.dispatch('fetchPartnerCategories');
    };

    const update = async () => {
      errors.value = null
      try {
        await updatePartner();
      } catch (e) {
        if (e.response?.data?.data) {
          errors.value = e.response.data.data
        }
        console.error(e);
      }
    };

    const toggleDestroyPartner = async () => {
      try {
        await changeConfirmationStateModal();
        await destroyPartner(route.params.id);
        await router.push({name: 'partners-list'});
      } catch (e) {
        console.error(e);
      }
    };

    const deleteFile = async (uuid) => {
      await destroyFile(partner.value.id, {uuid});
    }

    const changeConfirmationStateModal = () => {
      visibleModal.value = !visibleModal.value
      store.dispatch('changeStateModal', {
        type: 'confirmationDestroyPartnerModal',
        bool: visibleModal.value
      });
    };

    watch(() => form.value, () => isUpdate.value = false, {deep: true});

    onMounted(async () => {
      await loadPartnerCategories();

      breadcrumbs.value = [
        {label: 'Партнеры', router: {name: 'partners-list'}},
        {label: partner.value.name}
      ];
    });

    return {
      t,
      partner, files,
      form,
      partnerCategories,
      promoTypes,
      breadcrumbs,
      update,
      isUpdate,
      destroyFileMemory,
      loadFileMemory,
      deleteFile,
      visibleModal,
      v$,
      errors,
      changeConfirmationStateModal,
      toggleDestroyPartner,
      loadIconMemory,
      deleteIcon,
    };
  }
});
</script>

<template>
  <ConfirmationModal v-model:visible="visibleModal">
    <template #header>
      Удалить предложение
    </template>

    <template #default>
      <span>Вы уверенны, что хотите удалить предложение партнера?</span>
    </template>

    <template #footer>
      <div class="flex justify-content-between">
        <Button :label="t('labels.cancel')" @click="changeConfirmationStateModal"
                class="btn-primary-outlined font-light w-12"/>
        <Button :label="t('labels.destroy')" @click="toggleDestroyPartner" class="btn-primary font-light ml-3 w-12"/>
      </div>
    </template>
  </ConfirmationModal>

  <section class="py-2 mb-3">
    <div class="flex justify-content-between">
      <Breadcrumb :data="breadcrumbs" separator="/"/>

      <div class="flex">
        <Button
            v-if="!isUpdate"
            @click="update"
            label="Сохранить изменения"
            class="btn-primary font-light ml-3"
        />

        <ButtonSuccess class="ml-3" v-if="isUpdate" label="Изменения сохранены"/>
      </div>
    </div>
  </section>

  <section class="py-2 mb-3">
    <div class="gridLine">
      <div class="gridLine-item" >
        <MainCard title="Название партнера">
            <span class="p-float-label mb-3 w-full">
              <InputText
                  :class="{'p-invalid': v$.name.$errors.length }"
                  id="name"
                  class="w-full"
                  v-model="form.name"
              />
              <label for="name">Название</label>
            </span>
          <span v-if="v$.name.$errors.length || errors?.name" class="color-error">{{ errors?.name ? errors.name[0] : t(v$.name.$errors[0].$message) }}</span>
        </MainCard>
      </div>
      <div class="gridLine-item">
        <MainCard title="Идентификатор партнера">
          <Dropdown
              v-model="form.promo_type"
              :options="promoTypes"
              optionLabel="label"
              option-value="promo"
              placeholder="Идентификатор"
              class="w-full"/>
          <span v-if="errors?.promo_type" class="color-error">{{ errors.promo_type[0] }}</span>
        </MainCard>
      </div>
      <div class="gridLine-item">
        <MainCard title="Категория партнера">
          <Dropdown
              v-model="form.partner_category_id"
              :options="partnerCategories?.data?.data"
              :class="{'p-invalid': v$.partner_category_id.$errors.length }"
              optionLabel="name_ru"
              option-value="id"
              placeholder="Категории"
              class="w-full"/>
          <span v-if="errors?.partner_category_id" class="color-error">{{ errors.partner_category_id[0] }}</span>
        </MainCard>
      </div>
    </div>
  </section>

  <section class="py-2 mb-3">
    <div class="gridLine">
      <div class="gridLine-item">
        <MainCard title="Сайт партнера">
            <span class="p-float-label mb-3 w-full">
              <InputText
                  id="name"
                  :class="{'p-invalid': v$.site.$errors.length || errors?.site }"
                  class="w-full"
                  v-model="form.site"
              />
              <label for="name">https://link.com</label>
            </span>
          <span v-if="v$.site.$errors.length || errors?.site" class="color-error">{{ errors?.site ? errors.site[0] : t(v$.site.$errors[0].$message)}}</span>
        </MainCard>
      </div>
      <div class="gridLine-item">
        <MainCard title="Размер скидки">
            <span class="p-float-label mb-3 w-full">
              <InputText
                  :class="{'p-invalid': errors?.discount }"
                  id="name"
                  class="w-full"
                  v-model="form.discount"
              />
              <label for="name">Скидка (%)</label>
            </span>
          <span v-if="errors?.discount" class="color-error">{{ errors.discount[0] }}</span>
        </MainCard>
      </div>
      <div class="gridLine-item">
        <MainCard title="Erid">
            <span class="p-float-label mb-3 w-full">
              <InputText
                  :class="{'p-invalid': errors?.erid }"
                  id="name"
                  class="w-full"
                  v-model="form.erid"
              />
              <label for="name">Erid</label>
            </span>
          <span v-if="errors?.erid" class="color-error">{{ errors.erid[0] }}</span>
        </MainCard>
      </div>
    </div>
  </section>

  <section class="py-2 mb-3 grid">
    <div class="col-12 md:col-6">
      <MainCard title="Промокод">
            <span class="p-float-label mb-3 w-full">
              <InputText
                  :class="{'p-invalid': errors?.promo_value}"
                  id="name"
                  class="w-full"
                  v-model="form.promo_value"
              />
              <label for="name">Промокод</label>
            </span>
        <span v-if="errors?.promo_value" class="color-error">{{ errors?.promo_value ? errors.promo_value[0] : t(v$.promo_value.$errors[0].$message) }}</span>
      </MainCard>
    </div>
    <div class="col-12 md:col-6" style="height: 100%">
      <MainCard title="Логотип партнера">
        <div class="flex justify-content-between pb-2">
          <div v-if="form.logo" class="card__wrapper">
            <img :src="form.logo.original_url ? form.logo.original_url : form.logo.objectURL" class="mr-2 h-2rem w-2rem" alt=""/>
            <span>{{ form.logo.name ? form.logo.name : form.logo.original_url.split('/').pop() }}</span>
            <button @click="deleteIcon" class="card__delete">x</button>
          </div>
          <div>
            <ButtonFileUpload
                @chooseFiles="loadIconMemory"
                label="Загрузить файл"
                :multiple="false"
                :clear-files-after-select="true"
                :classes="{'btn-fix-height': true}"
            />
          </div>
          <span  v-if="errors?.logo" class="flex text-xs color-error mb-2">
              {{ errors.logo }}
          </span>
        </div>
      </MainCard>
    </div>
  </section>

  <section class="py-2 mb-3">
    <MainCard title="Описание предложения">
      <div class="grid">
        <div class="col-12">
          <Editor v-model="form.description_ru" class="w-full"></Editor>
          <span v-if="v$.description_ru.$errors.length || errors?.description_ru" class="text-xs color-error">
            {{ errors?.description_ru ? errors.description_ru[0] : t(v$.description_ru.$errors[0].$message) }}
          </span>
        </div>
      </div>
    </MainCard>
  </section>

  <section class="py-2 mb-3">
    <MainCard title="Изображения">
      <span v-if="errors?.photo" class="flex text-xs color-error mb-2">
              {{ errors.photo[0] }}
            </span>
      <div class="grid">
        <div v-for="(image, i) in partner.photos" :key="i" class="col-12 md:col-2">
          <ImageCard :src="image.preview_url" :handle="() => deleteFile(image.uuid)"/>
        </div>

        <template v-if="files.length">
          <div v-for="(file, i) in files" :key="i" class="col-12 md:col-2">
            <ImageCard :src="file.objectURL" :handle="() => destroyFileMemory(file)"/>
          </div>
        </template>


        <div class="col-12">
          <ButtonFileUpload @chooseFiles="loadFileMemory" label="Добавить изображение" :multiple="true"
                            :clear-files-after-select="true"/>
        </div>
      </div>
    </MainCard>
  </section>

  <section  class="py-2 mb-4">
    <div class="flex justify-content-end">
      <span class="color-error underline cursor-pointer" @click="changeConfirmationStateModal">
        Удалить партнера
      </span>
    </div>
  </section>
</template>

<style scoped>
.card__wrapper {
  width: 60%;
  display: flex;
  align-items: center;
  padding: 5px 16px;
  gap: 5px;
  border: 1px solid #DBD9D9;
  border-radius: 4px;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
}
.card__delete {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
}

.gridLine {
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media screen and (min-width: 1024px)  {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  :deep(.p-card),
  :deep(.p-card-body) {
    width: 100%;
    height: 100%;
  }
}
.gridLine-item {
  display: flex;
  width: 100%;
}

</style>