import http from '../plugins/axios';

const downloadPayments = async (params = {}) => {
  const response = await http.get('/api/company/v1/payment/download', { params,  responseType: 'blob' });
  return response.data;
};

export {
  downloadPayments
};
