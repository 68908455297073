<script>
import {computed, defineComponent, onBeforeMount, reactive, ref} from "vue";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Editor from "primevue/editor";
import MainCard from "@/components/cards/MainCard";
import Breadcrumb from "@/components/Breadcrumb";
import ButtonSuccess from "@/components/buttons/ButtonSuccess";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload.vue";
import ImageCard from "@/components/cards/ImageCard.vue";
import {useI18n} from "vue-i18n";
import {useRoute, useRouter} from "vue-router";
import {useVuelidate} from "@vuelidate/core";
import {useApiCall} from "@/composables/useApiCall";
import {
  addInfrastructureItemApiCall,
  deletePhotoInfrastructureApiCall,
  infrastructureItemApiCall,
  updateInfrastructureItemApiCall,
  uploadPhotoInfrastructureApiCall,
  deleteInfrastructureApiCall,
  updatePhotoCaptionApiCall,
} from "@/services/infrastructure";
import {required} from "@/utils/i18n-validators";
import Skeleton from "primevue/skeleton"
import BrandCard from "@/components/cards/BrandCard.vue";
import WebsiteAddButton from "@/components/website/WebsiteAddButton.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import InfrastructureImages from "@/components/website/InfrastructureImages.vue";

export default defineComponent({
  layout: {name: 'AdminLayout'},
  components: {
    InfrastructureImages,
    ConfirmationModal,
    WebsiteAddButton,
    BrandCard,
    ButtonSuccess,
    Button,
    InputText,
    MainCard,
    Breadcrumb,
    Editor,
    ButtonFileUpload,
    ImageCard,
    Skeleton,
  },
  setup() {
    const {t} = useI18n();
    const router = useRouter()
    const route = useRoute()
    const errors = ref(null)
    const breadcrumbs = ref([]);
    const isLoading = ref(true)
    const parentId = ref(null)
    const form = reactive({
      fields: {
        id: 'newItem',
        title_ru: '',
        description_ru: '',
        photos: [],
        link: '',
        request_id: '',
        price: null,
      }
    })
    const rules = {
      id: {},
      title_ru: {required},
      description_ru: {required},
      photos: {required},
    };
    const v$ = useVuelidate(rules, form.fields);

    const deleteUuids = ref([])
    const deleteItem = ref(null)
    const validError = ref(false)
    const visible = ref(false)
    const isError = computed(() =>
        contentUpdateError.value ||
        contentAddError.value ||
        contentAddPhotoError.value ||
        contentDeletePhotoError.value ||
        captionUpdateError.value
    )
    const isUpdateLoading = computed(() =>
        isContentUpdateLoading.value ||
        isContentAddLoading.value ||
        isContentAddPhotoLoading.value ||
        isContentDeletePhotoLoading.value ||
        isCaptionUpdateLoading.value
    )
    const {
      data: contentData,
      error: contentError,
      executeApiCall: getContentAction,
    } = useApiCall(infrastructureItemApiCall, true)

    const {
      isLoading: isContentUpdateLoading,
      data: contentUpdateData,
      error: contentUpdateError,
      executeApiCall: updateContentAction,
    } = useApiCall(updateInfrastructureItemApiCall, true)

    const {
      isLoading: isCaptionUpdateLoading,
      data: captionUpdateData,
      error: captionUpdateError,
      executeApiCall: updateCaptionAction,
    } = useApiCall(updatePhotoCaptionApiCall, true)


    const {
      isLoading: isContentDeleteLoading,
      data: contentDeleteData,
      error: contentDeleteError,
      executeApiCall: deleteContentAction,
    } = useApiCall(    deleteInfrastructureApiCall, true)

    const {
      isLoading: isContentAddLoading,
      data: contentAddData,
      error: contentAddError,
      executeApiCall: addContentAction,
    } = useApiCall(addInfrastructureItemApiCall, true)

    const {
      isLoading: isContentAddPhotoLoading,
      data: contentAddPhotoData,
      error: contentAddPhotoError,
      executeApiCall: addContentPhotoAction,
    } = useApiCall(uploadPhotoInfrastructureApiCall, true)

    const {
      isLoading: isContentDeletePhotoLoading,
      data: contentDeletePhotoData,
      error: contentDeletePhotoError,
      executeApiCall: deleteContentPhotoAction,
    } = useApiCall(deletePhotoInfrastructureApiCall, true)


    const loadFileMemory = (data) => {
      data.files.forEach((item) => {
        form.fields.photos.push({
          id: 'NewImage' + Date.now(),
          file: item,
          caption: '',
        })
      })
    }

    const deleteSlide  = (data) => {
      if (!data.id.toString().includes("NewImage")) {
        deleteUuids.value.push(data.uuid)
      }
      form.fields.photos = form.fields.photos.filter(item => item.id !== data.id)
    }

    const updateCaption = async (item, caption) => {
      const findIndex = form.fields.photos.findIndex(photo => photo.id === item.id)
      if (findIndex !== -1) {
        form.fields.photos[findIndex].caption = caption
      }
    }

    const update = async () => {
      errors.value = null
      const result = await v$.value.$validate()
      if(!result) return
      if (form.fields.id.toString().includes('newItem')) {
        try {
          const formData = new FormData()
          formData.append('parent_id', parentId.value)
          formData.append('title_ru', form.fields.title_ru)
          formData.append('description_ru', form.fields.description_ru)
          formData.append('photo', form.fields.photos[0].file)
          if (form.fields.photos[0].caption) {
            formData.append('caption', form.fields.photos[0].caption)
          }
          if (form.fields.link) {
            formData.append('link', form.fields.link)
          }
          if (form.fields.request_id) {
            formData.append('request_id', form.fields.request_id)
          }
          await addContentAction(formData)
          if (contentAddData.value) {
            form.fields.id = contentAddData.value.data.id
            if (form.fields.photos[0].caption) {
              await updateCaptionAction({
                id: form.fields.id,
                uuid: contentAddData.value.data.photos[0].uuid,
                fields: {
                  caption: form.fields.photos[0].caption,
                }
              })
            }
            form.fields.photos[0] = {...contentAddData.value.data.photos[0], caption: form.fields.photos[0].caption}
            contentData.value = { data: {...contentAddData.value.data, photos: form.fields.photos} }
          }
        } catch (e) {
          errors.value = contentAddError.value.data
          console.error(e)
          return
        }
      } else {
        try {
          await updateContentAction({
            id: form.fields.id,
            fields: {
              title_ru: form.fields.title_ru,
              description_ru: form.fields.description_ru,
              link: form.fields.link,
              request_id: form.fields.request_id,
            }
          })
        } catch (e) {
          errors.value = contentUpdateError.value.data
          console.error(e)
          return
        }
      }
      if (deleteUuids.value.length) {
        for (let i = 0; i < deleteUuids.value.length; i += 1) {
          try {
            await deleteContentPhotoAction({
              id: form.fields.id,
              fields: {
                uuid: deleteUuids.value[i]
              }
            })
            contentData.value.data.photos = contentData.value.data.photos.filter(item => item.uuid !== deleteUuids.value[i])
          } catch (e) {
            console.error(e)
            return
          }
        }
        deleteUuids.value = []
      }
      for (let i = 0; i < form.fields.photos.length; i += 1) {
        if (form.fields.photos[i].file) {
          try {
            const formData = new FormData()
            formData.append('photo', form.fields.photos[i].file)
            await addContentPhotoAction({
                  id: form.fields.id,
                  fields: formData,
                }
            )
            if (contentAddPhotoData.value?.data && form.fields.photos[i].caption) {
              await updateCaptionAction({
                id: form.fields.id,
                uuid: contentAddPhotoData.value?.data?.photos[i].uuid,
                fields: {
                  caption: form.fields.photos[i].caption,
                }
              })
              contentData.value.data.photos[i] = {...contentAddPhotoData.value.data?.photos[i], caption: form.fields.photos[i].caption}
              form.fields.photos[i] = {...contentAddPhotoData.value.data?.photos[i], caption: form.fields.photos[i].caption}
            }
          } catch (e) {
            errors.value = {imageError: contentAddPhotoError.value?.data, index: form.fields.photos[i].objectURL}
            console.error(e)
            return
          }
        } else {
          if (contentData.value.data.photos[i].caption !== form.fields.photos[i].caption) {
            if (form.fields.photos[i].caption) {
              await updateCaptionAction({
                id: form.fields.id,
                uuid: contentData.value.data.photos[i].uuid,
                fields: {
                  caption: form.fields.photos[i].caption,
                }
              })
              contentData.value.data.photos[i].caption = form.fields.photos[i].caption
            }
          }
        }
      }
      if (!isError.value) {
        await router.push({name: 'website', query: {tab: 'apartments'}})
      }
    }

    const changeVisibleModal = (index = null) => {
      visible.value = !visible.value
      if (index || index === 0) {
        deleteItem.value = index
      }
    };

    const deleteHandler = async () => {
      try {
        await deleteContentAction({
          id: form.fields.id,
        })
        if (contentDeleteData.value) {
          await router.push({name: 'website', query: {tab: 'apartments'}})
        }
      } catch (e) {
        console.error(e)
      }
    }

    onBeforeMount(async () => {
      window.scrollTo(0, 0);
      if (route.params.id) {
        try {
          await getContentAction({id: route.params.id})
          if (contentData.value) {
            form.fields.id = contentData.value.data.id
            form.fields.title_ru = contentData.value.data.title_ru
            form.fields.description_ru = contentData.value.data.description_ru
            form.fields.link = contentData.value.data.link
            form.fields.price = contentData.value.data.price
            form.fields.request_id = contentData.value.data.request_id
            form.fields.photos = JSON.parse(JSON.stringify(contentData.value.data.photos))
          }
        } catch (e) {
          console.error(e)
        }
      }
      else {
        await getContentAction({id: 'permanent'})
        if (contentData.value) {
          parentId.value = contentData.value.data.id
        }
      }
      breadcrumbs.value = [
        { label: 'Сайт / Инфраструктура / Апартаменты', router: {name: 'website', query: {tab: 'apartments'}} },
        {label: contentData.value ? contentData.value.data.title_ru : 'Создание категории'}
      ];
      isLoading.value = false
    });

    return {
      t,
      form,
      breadcrumbs,
      update,
      loadFileMemory,
      v$,
      errors,
      isLoading,
      deleteItem,
      deleteHandler,
      changeVisibleModal,
      visible,
      validError,
      route,
      isUpdateLoading,
      isContentDeleteLoading,
      deleteSlide,
      updateCaption,
    };
  }
});
</script>

<template>
  <ConfirmationModal :visible="visible">
    <template #header>
      Удалить категорию
    </template>

    <template #default>
      <span>Вы уверены,  что хотите удалить категорию? Это действие будет невозможно отменить</span>
    </template>

    <template #footer>
      <div class="flex justify-content-between">
        <Button :label="t('labels.cancel')" @click="changeVisibleModal(null)" class="btn-primary-outlined font-light w-12"/>
        <Button :label="t('labels.destroy')" :loading="isContentDeleteLoading" @click="deleteHandler" class="btn-primary font-light ml-3 w-12"/>
      </div>
    </template>
  </ConfirmationModal>
  <section class="py-2 mb-3">
    <div class="flex justify-content-between">
      <Breadcrumb :data="breadcrumbs" separator="/"/>
      <div class="flex">
        <Button
            @click="update"
            :loading="isUpdateLoading"
            label="Сохранить изменения"
            :class="['btn-primary btn-fix-height font-light ml-3']"
        />
      </div>
    </div>
  </section>
  <section class="py-2 mb-3">
    <Skeleton v-if="isLoading" height="154px"></Skeleton>
    <MainCard v-else title="Название категории" class="w-full">
      <span class="p-float-label mb-3 w-full">
        <InputText
          :class="['w-full', {'p-invalid': v$.title_ru.$errors?.length || errors?.title_ru }]"
          id="name_ru"
          v-model="form.fields.title_ru"
        />
        <label for="name_ru">Введите название категории <i style="color: #E26B6B">*</i></label>
      </span>
      <span v-if="v$.title_ru.$errors?.length || errors?.title_ru" class="color-error">{{ errors?.title_ru ? errors.title_ru[0] : t(v$.title_ru.$errors[0].$message) }}</span>
    </MainCard>
  </section>
  <section class="py-2 mb-3">
    <span class="infrastructure__slides-title">Изображения слайдера</span>
      <div class="infrastructure__slides">
        <InfrastructureImages
            v-for="(file, i) in form.fields.photos"
            :item="file"
            :index="i"
            :key="file.id"
            @deleteSlide="deleteSlide"
            @updateCaption="updateCaption"
        />
        <div class="infrastructure-grid__button">
          <ButtonFileUpload
              withPlus
              @chooseFiles="loadFileMemory"
              label="Добавить слайд"
              :multiple="false"
              :clear-files-after-select="true"
          />
        </div>
      </div>
    <span v-if="v$.photos.$errors.length || errors?.photo" class="flex text-xs color-error mb-2 mt-2">
              {{ errors?.photo ? errors.photo[0] : t(v$.photos.$errors[0].$message) }}
    </span>
  </section>
  <section class="py-2 mb-3">
    <MainCard title="Описание">
      <div class="grid">
        <div class="col-12">
          <Editor v-model="form.fields.description_ru" class="w-full"></Editor>
          <span v-if="v$.description_ru.$errors?.length || errors?.description_ru" class="color-error">{{ errors?.description_ru ? errors.description_ru[0] : t(v$.description_ru.$errors[0].$message) }}</span>
        </div>
      </div>
    </MainCard>
  </section>
  <section v-if="route.params.id" class="py-2 mb-4">
    <div class="flex justify-content-end">
      <span class="color-error underline cursor-pointer" @click="changeVisibleModal(null)">
        Удалить категорию
      </span>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.infrastructure {
  &__slides {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 30px;
    row-gap: 16px;
    word-break: break-all;
    @media screen and (min-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (min-width: 1400px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.infrastructure-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  row-gap: 16px;
}
.infrastructure-grid__button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #C3987A;
  min-height: 412px;
  border-radius: 10px;
  :deep(.p-button-label) {
    background: unset;
    color: #C3987A;
  }
  :deep(.btn-primary) {
    background: unset !important;
    border-color: transparent !important;
  }
}
.infrastructure__slides-title {
  display: flex;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 15px;
}
.card__wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #DBD9D9;
  padding: 12px 16px;
  border-radius: 4px;
  margin-bottom: 16px;
  span {
    word-break: break-all;
  }
}
.card__delete {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
}
</style>