<script>
import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  watch
} from "vue";
import {useMeta} from "vue-meta";
import TabMenu from "primevue/tabmenu";
import Paginator from "primevue/paginator";
import MainCard from "@/components/cards/MainCard";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal";
import Editor from "primevue/editor";
import {useI18n} from "vue-i18n";
import ImageCard from "@/components/cards/ImageCard.vue";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload.vue";
import WebsiteBrand from "@/views/website/tabs/WebsiteBrand.vue";
import WebsiteMain from "@/views/website/tabs/WebsiteMain.vue";
import WebsiteGallery from "@/views/website/tabs/WebsiteGallery.vue"
import WebsiteInfrastructure from "@/views/websiteInfrastructure/WebsiteInfrastructure.vue";
import WebsiteInfrastructureDraft from "@/views/websiteInfrastructure/WebsiteInfrastructureDraft.vue";
import Skeleton from "primevue/skeleton"
import Button from "primevue/button";
import {useRoute, useRouter} from "vue-router";
import { useApiCall } from "@/composables/useApiCall";
import { articlesApiCall, servicesApiCall } from "@/services/articles"
import { infrastructureInfoApiCall } from "@/services/infrastructure";
export default defineComponent({
  layout: { name: 'AdminLayout' },
  components: {
    ButtonFileUpload,
    ImageCard,
    Editor,
    Breadcrumb,
    MainCard,
    TabMenu,
    Paginator,
    ConfirmationModal,
    WebsiteBrand,
    WebsiteMain,
    Button,
    Skeleton,
    WebsiteInfrastructure,
    WebsiteGallery,
    WebsiteInfrastructureDraft,
  },
  setup() {
    const {t} = useI18n()
    useMeta({
      title: t('menu.website'),
    });
    const activeTabIndex = ref(0);
    const isUpdated = ref(false);
    const errors = ref(null);
    const galleryItems = ref([])
    const newsItems = ref([])
    const servicesItems = ref([])
    const deleteUuid = ref(null)
    const infrastructure = ref([])
    const activeTabComponent = computed(() => tabs.value[activeTabIndex.value]);
    const visible = ref(false)
    const router = useRouter();
    const route = useRoute();
    const tabs = ref([
      {label: 'Главная страница', component: 'main' },
      {label: 'Фотогалерея', component: 'photos'},
      {label: 'Акции и новости', component: 'news'},
      {label: 'О бренде', component: 'brand'},
      {label: 'Услуги', component: 'services'},
      {label: 'Инфраструктура', component: 'infrastructure'},
      {label: 'Апартаменты', component: 'apartments'},
    ]);
    const {
      isLoading: isArticlesLoading,
      data: articlesData,
      error: articlesError,
      executeApiCall: getArticlesAction,
    } = useApiCall(articlesApiCall, true)
    const {
      isLoading: isServicesLoading,
      data: servicesData,
      error: servicesError,
      executeApiCall: getServicesAction,
    } = useApiCall(servicesApiCall, true)
    const {
      isLoading: isInfrastructureLoading,
      data: infrastructureData,
      error: infrastructureError,
      executeApiCall: getInfrastructureAction,
    } = useApiCall(infrastructureInfoApiCall, true)

    const changeVisibleModal = (uuid = null) => {
      visible.value = !visible.value
      if (uuid) {
        deleteUuid.value = uuid
      }
    };

    const linkOptions = computed(() => {
      switch (activeTabComponent.value.component) {
        case 'news':
          return {
            visible: true,
            link: 'website-news-add',
            text: 'Создать'
          }
        case 'services':
          return {
          visible: true,
          link: 'website-services-add',
          text: 'Создать услугу'
        }
        case 'infrastructure':
          return {
          visible: true,
          link: 'website-infrastructure-add',
          text: 'Создать'
        }
        default:
          return {
            visible: false,
            link: '',
            text: ''
          }
      }
    })

    watch(() => activeTabIndex.value,
        async () => {
          if (!activeTabIndex.value) {
            await router.push({query: {}})
          } else {
            await router.push({query: {tab: activeTabComponent.value.component}})
          }
          switch (activeTabComponent.value.component) {
            case 'news':
              try {
                await getArticlesAction()
                if (articlesData.value?.data) {
                  newsItems.value = [...articlesData.value?.data.data]
                }
              } catch (e) {
                console.error(e)
              }
              return
            case 'services':
              try {
                await getServicesAction()
                if (servicesData.value?.data) {
                  servicesItems.value = [...servicesData.value?.data.data]
                }
              } catch (e) {
                console.error(e)
              }
              return
            case 'infrastructure':
              try {
                await getInfrastructureAction()
                if (infrastructureData.value?.data) {
                  infrastructure.value = [...infrastructureData.value?.data]
                }
              } catch (e) {
                console.error(e)
              }
              return
          }
        }, {deep: true}
    )

    onBeforeMount(async () => {
      if (route.query?.tab) {
        activeTabIndex.value = tabs.value.findIndex(item => item.component === route.query.tab)
      }
    })

    return {
      tabs,
      linkOptions,
      isUpdated,
      activeTabIndex,
      activeTabComponent,
      errors,
      t,
      galleryItems,
      visible,
      changeVisibleModal,
      newsItems,
      isArticlesLoading,
      servicesItems,
      isServicesLoading,
      route,
      infrastructure,
      isInfrastructureLoading,
    };
  },
})
</script>

<template>
  <div class="relative">
    <section class="py-2">
      <div class="flex justify-content-between">
        <h1 class="website__title" style="min-height: 46px">{{ t('menu.website') }}</h1>
        <router-link
            v-if="linkOptions.visible"
            :to="{ name: linkOptions.link}"
            class="p-button btn-fix-height btn-black-20-outlined website__link"
        >{{ linkOptions.text }}</router-link>
      </div>
    </section>
    <section class="py-2 mb-3">
      <TabMenu v-model:active-index="activeTabIndex" :model="tabs" />
    </section>
    <template v-if="activeTabComponent.component === 'main'">
      <WebsiteMain />
    </template>
    <template v-if="activeTabComponent.component === 'brand'">
      <WebsiteBrand />
    </template>
    <template v-if="activeTabComponent.component === 'photos'">
      <WebsiteGallery />
    </template>
    <template v-if="activeTabComponent.component === 'news'">
      <Skeleton v-if="isArticlesLoading" height="187px"></Skeleton>
      <div v-else-if="newsItems.length" class="website__news">
        <div v-for="(item, i) in newsItems" :key="i" class="website__news-item">
          <router-link
              :to="{name: 'website-news-item', params: {id: item.id}}"
              class="flex flex-column gap-3"
          >
            <span class="website__news-item-date">{{item.date}}</span>
            <span class="website__news-item-name">{{item.title_ru}}</span>
          </router-link>
        </div>
      </div>
      <div v-else class="website__empty">
        <span>Акции и новости отсутстувют</span>
      </div>
    </template>

    <template v-if="activeTabComponent.component === 'services'">
      <Skeleton v-if="isServicesLoading" height="187px"></Skeleton>
      <div v-else-if="servicesItems.length" class="website__services">
        <div v-for="(item, i) in servicesItems" :key="i" class="flex">
          <router-link
              :to="{name: 'website-services-item', params: {id: item.id}}"
              class="website__services-item"
          >
            <div class="website__services-item-image">
              <img v-if="item.photo.preview_url" :src="item.photo.preview_url" />
              <div v-else />
            </div>
            <div class="flex flex-column">
              <span class="website__services-item-name">{{item.title_ru}}</span>
              <span class="website__services-item-description" v-html="item.description_ru" />
            </div>
          </router-link>
        </div>
      </div>
      <div v-else class="website__empty">
        <span>Услуги отсутстувют</span>
      </div>
    </template>
    <template v-if="activeTabComponent.component === 'apartments'">
      <WebsiteInfrastructureDraft />
    </template>
    <template v-if="activeTabComponent.component === 'infrastructure'">
      <Skeleton v-if="isInfrastructureLoading" height="325px"></Skeleton>
      <div v-else-if="infrastructure.length" class="website__infrastructure">
        <router-link
            v-for="(item, i) in infrastructure"
            :key="i"
            :to="{name: 'website-infrastructure-item', params: {id: item.id}}"
            class="website__infrastructure-item"
        >
          <div v-if="item.photo" class="website__infrastructure-item-wrapper">
            <img :src="item.photo.original_url" alt="infrastructure" class="website__infrastructure-item-image">
          </div>
          <div class="website__infrastructure-item-name">
            <span>{{item.title_ru}}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
              <path d="M5.41699 13H20.5837" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13 5.41797L20.5833 13.0013L13 20.5846" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </router-link>
      </div>
      <div v-else class="website__empty">
        <span>Данные отсутстувют</span>
      </div>
    </template>
  </div>

</template>
<style lang="scss" scoped>
.website {
  &__title {
    min-height: 37px;
  }
  &__link {
    display: flex;
    text-decoration: unset;
    padding: 8px 85px;
  }
  &__gallery {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
    gap: 16px;
    @media screen and (min-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media screen and (min-width: 1400px) {
      grid-template-columns: repeat(6, 1fr);
    }
  }
  &__services {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 30px;
    row-gap: 16px;
    @media screen and (min-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }
    &-item {
      display: grid;
      grid-template-columns: 216px calc(100% - 236px);
      gap: 20px;
      width: 100%;
      position: relative;
      min-height: 135px;
      background: white;
      box-shadow: 0 4px 25px 0 #171C2114;
      padding: 12px;
      border-radius: 10px;
      text-decoration: unset !important;
      &-name {
        display: flex;
        margin-bottom: 4px;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        color: var(--color-black-80);
      }
      &-description {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        height: 64px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: var(--color-black-40);
      }
      &-image {
        display: flex;
        width: 216px;
        height: 111px;
        div,
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          background: var(--color-black-20);
          border-radius: 6px;
        }
      }
    }
  }
  &__infrastructure,
  &__news {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 30px;
    gap: 20px;
    @media screen and (min-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }
    &-item {
      display: flex;
      position: relative;
      min-height: 200px;
      background: white;
      box-shadow: 0 4px 25px 0 #171C2114;
      padding: 24px;
      border-radius: 10px;
      a {
        text-decoration: unset !important;
      }
      &-date {
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        color: var(--color-primary);
      }
      &-name {
        display: flex;
        align-items: center;
        gap: 12px;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        color: var(--color-black-80);
        z-index: 1;
        svg {
          margin-top: 5px;
        }
      }
    }
    &-close {
      position: absolute;
      top: 21px;
      right: 21px;
    }
  }
  &__infrastructure {
    position: relative;
    margin-bottom: 60px;
    &-item {
      min-height: 325px;
      background: var(--color-black-20);
      text-decoration: none;
      word-break: break-all;
      &-name {
        margin-top: auto;
        color: var(--color-white);
        font-size: 30px;
        font-weight: 600;
        line-height: 36px;
      }
      &-wrapper {
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      &-image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 10px;

      }
    }
  }
  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 250px;
    width: 100%;
    height: 100%;
    color: var(--color-black-40);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
}
::v-deep(.p-menuitem-text) {
  white-space: nowrap;
}
</style>