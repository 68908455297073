import {useStore} from "vuex";
import {computed, ref} from "vue";
import {required, site} from "@/utils/i18n-validators";
import {useVuelidate} from "@vuelidate/core";

export function usePartners(globalConfig) {
    const store = useStore();

    const form = ref({
        name: '',
        promo_type: '',
        partner_category_id: '',
        description_ru: '',
        description_en: '',
        description_ch: '',
        promo_value: '',
        logo: null,
        discount: '',
        site: '',
        erid: '',
    });

    const rules = {
        name: {required},
        promo_type: {required},
        description_ru: {required},
        partner_category_id: {required},
        site: {site}
    };

    const v$ = useVuelidate(rules, form);

    const partner = computed(() => store.getters.getCurrentPartner);

    const files = ref([]);
    const isUpdate = ref(false);

    const loadPartner = async (id) => {
        await store.dispatch('fetchPartner', id);
        files.value = [];
        form.value.logo = partner.value.logo
    };

    const uploadFiles = async () => {
        const requests = [];
        for (let file of files.value) {
            const formData = new FormData();
            formData.set('photo', file);
            requests.push(store.dispatch('fetchUploadPartnerPhoto', {
                id: partner.value.id,
                body: formData
            }));
        }

        await Promise.all(requests);
    };

    const updatePartner = async () => {
        const result = await v$.value.$validate();
        if (!result) {
            return;
        }
        // const updateDataForm = {};
        const updateDataForm = new FormData;

        for (let key in form.value) {
            if (key === 'photos') continue;
            if (key === 'logo' && (!form.value.logo || form.value.logo.original_url)) {
                continue;
            } else {
                updateDataForm.append(key, form.value[key] ? form.value[key] : '');
            }
            // updateDataForm.append(key, form.value[key])

            // updateDataForm[key] = form.value[key];
        }

        await store.dispatch('fetchUpdatePartner', {
            id: partner.value.id,
            body: updateDataForm
        });

        if (files.value.length) {
            await uploadFiles();
        }
        await loadPartner(partner.value.id)
        isUpdate.value = true;

    };

    const createPartner = async () => {
        const dataForm = new FormData;
        for (let key in form.value) {
            if (key === 'logo' && !form.value.logo) {
              continue;
            } else {
              dataForm.append(key, form.value[key])
            }
        }
        files.value.forEach((item) => {
            dataForm.append('photos[]', item)
        })


        await store.dispatch('fetchCreatePartner',
            {body: dataForm}
        );
    };


    const loadFileMemory = ({files: photos}) => {
        isUpdate.value = false
        files.value.push(...photos);
    };

    const loadIconMemory = (data) => {
        isUpdate.value = false
        form.value.logo = data.files[0]
    }

    const deleteIcon = async() => {
        if (form.value.logo && form.value.logo.original_url) {
          const body = { uuid: form.value.logo.uuid }
          await store.dispatch('fetchDestroyPartnerLogo', {id: partner.value.id, body});
        }
        form.value.logo = null
    }

    const destroyFileMemory = (file) => {
        isUpdate.value = false
        files.value = files.value.filter(f => f !== file);
    };

    const destroyFile = async (id, body) => {
        isUpdate.value = false
        await store.dispatch('fetchDestroyPartnerPhoto', {id, body});
    };

    const destroyPartner = async (id) => {
        await store.dispatch('fetchDestroyPartner', {id});
    }

    return {
        partner, form, files, v$, isUpdate,
        uploadFiles, loadFileMemory, loadPartner,
        updatePartner,
        destroyFileMemory, destroyFile, destroyPartner, createPartner,
        loadIconMemory, deleteIcon
    };
}