<script>
import {computed, defineComponent, onBeforeMount, reactive, ref} from "vue";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Editor from "primevue/editor";
import MainCard from "@/components/cards/MainCard";
import Breadcrumb from "@/components/Breadcrumb";
import ButtonSuccess from "@/components/buttons/ButtonSuccess";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload.vue";
import ImageCard from "@/components/cards/ImageCard.vue";
import {useI18n} from "vue-i18n";
import {useRoute, useRouter} from "vue-router";
import {useVuelidate} from "@vuelidate/core";
import {useApiCall} from "@/composables/useApiCall";
import {
  addAdvantageInfrastructureApiCall, addInfrastructureItemApiCall,
  deleteAdvantageInfrastructureApiCall,
  deletePhotoInfrastructureApiCall,
  infrastructureItemApiCall,
  updateAdvantageInfrastructureApiCall,
  updateInfrastructureItemApiCall,
  uploadPhotoInfrastructureApiCall,
  deleteInfrastructureApiCall,
} from "@/services/infrastructure";
import {required} from "@/utils/i18n-validators";
import Skeleton from "primevue/skeleton"
import BrandCard from "@/components/cards/BrandCard.vue";
import WebsiteAddButton from "@/components/website/WebsiteAddButton.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";

export default defineComponent({
  layout: {name: 'AdminLayout'},
  components: {
    ConfirmationModal,
    WebsiteAddButton,
    BrandCard,
    ButtonSuccess,
    Button,
    InputText,
    MainCard,
    Breadcrumb,
    Editor,
    ButtonFileUpload,
    ImageCard,
    Skeleton,
  },
  setup() {
    const {t} = useI18n();
    const router = useRouter()
    const route = useRoute()
    const errors = ref(null)
    const breadcrumbs = ref([]);
    const isLoading = ref(true)
    const form = reactive({
      fields: {
        id: 'newItem',
        title_ru: '',
        description_ru: '',
        photos: [],
        advantages: [],
      }
    })
    const rules = {
      id: {},
      title_ru: {required},
      description_ru: {required},
      photos: {required},
      advantages: {},
    };
    const v$ = useVuelidate(rules, form.fields, { $scope: 'advantages' });
    const deleteUuids = ref([])
    const deleteItem = ref(null)
    const validError = ref(false)
    const visible = ref(false)
    const isPermanent = computed(() => route.name === 'website')
    const isError = computed(() =>
        contentUpdateError.value ||
        contentAddError.value ||
        contentAddPhotoError.value ||
        contentDeletePhotoError.value ||
        contentAddAdvantageError.value ||
        contentUpdateAdvantageError.value
    )
    const isUpdateLoading = computed(() =>
        isContentUpdateLoading.value ||
        isContentAddLoading.value ||
        isContentAddPhotoLoading.value ||
        isContentDeletePhotoLoading.value ||
        isContentAddAdvantageLoading.value ||
        isContentUpdateAdvantageLoading.value
    )
    const {
      data: contentData,
      error: contentError,
      executeApiCall: getContentAction,
    } = useApiCall(infrastructureItemApiCall, true)

    const {
      isLoading: isContentUpdateLoading,
      data: contentUpdateData,
      error: contentUpdateError,
      executeApiCall: updateContentAction,
    } = useApiCall(updateInfrastructureItemApiCall, true)

    const {
      isLoading: isContentDeleteLoading,
      data: contentDeleteData,
      error: contentDeleteError,
      executeApiCall: deleteContentAction,
    } = useApiCall(    deleteInfrastructureApiCall, true)

    const {
      isLoading: isContentAddLoading,
      data: contentAddData,
      error: contentAddError,
      executeApiCall: addContentAction,
    } = useApiCall(addInfrastructureItemApiCall, true)

    const {
      isLoading: isContentAddPhotoLoading,
      data: contentAddPhotoData,
      error: contentAddPhotoError,
      executeApiCall: addContentPhotoAction,
    } = useApiCall(uploadPhotoInfrastructureApiCall, true)

    const {
      isLoading: isContentDeletePhotoLoading,
      data: contentDeletePhotoData,
      error: contentDeletePhotoError,
      executeApiCall: deleteContentPhotoAction,
    } = useApiCall(deletePhotoInfrastructureApiCall, true)

    const {
      isLoading: isContentAddAdvantageLoading,
      data: contentAddAdvantageData,
      error: contentAddAdvantageError,
      executeApiCall: addAdvantageAction,
    } = useApiCall(addAdvantageInfrastructureApiCall, true)

    const {
      isLoading: isContentUpdateAdvantageLoading,
      data: contentUpdateAdvantageData,
      error: contentUpdateAdvantageError,
      executeApiCall: updateAdvantageAction,
    } = useApiCall(updateAdvantageInfrastructureApiCall, true)

    const {
      isLoading: isContentDeleteAdvantageLoading,
      data: contentDeleteAdvantageData,
      error: contentDeleteAdvantageError,
      executeApiCall: deleteAdvantageAction,
    } = useApiCall(deleteAdvantageInfrastructureApiCall, true)

    const deletePhoto = (photo) => {
      if (photo.id) {
        deleteUuids.value.push(photo.uuid)
      }
      form.fields.photos = form.fields.photos.filter(item => item !== photo)
    }
    const loadFileMemory = (data) => {
      form.fields.photos = form.fields.photos.concat(data.files)
    }
    const changeAdvantages = (data, index) => {
      form.fields.advantages[index] = data
    }

    const update = async () => {
      errors.value = null
      const result = await v$.value.$validate()
      if(!result) return
      if (form.fields.id.toString().includes('newItem')) {
        try {
          const formData = new FormData()
          formData.append('title_ru', form.fields.title_ru)
          formData.append('description_ru', form.fields.description_ru)
          formData.append('photo', form.fields.photos[0])
          await addContentAction(formData)
          if (contentAddData.value) {
            form.fields.id = contentAddData.value.data.id
            form.fields.photos[0] = {...contentAddData.value.data.photos[0]}
            contentData.value = { data: {...contentAddData.value.data}}
          }
        } catch (e) {
          errors.value = contentAddError.value.data
          console.error(e)
          return
        }
      } else {
        try {
          await updateContentAction({
            id: isPermanent.value ? 'permanent' : form.fields.id,
            fields: {
              title_ru: form.fields.title_ru,
              description_ru: form.fields.description_ru,
            }
          })
        } catch (e) {
          errors.value = contentUpdateError.value.data
          console.error(e)
          return
        }
        if (deleteUuids.value.length) {
          for (let i = 0; i < deleteUuids.value.length; i += 1) {
            try {
              await deleteContentPhotoAction({
                id: form.fields.id,
                fields: {
                  uuid: deleteUuids.value[i]
                }
              })
            } catch (e) {
              console.error(e)
              return
            }
          }
          deleteUuids.value = []
        }
        for (let i = 0; i < form.fields.photos.length; i += 1) {
          if (form.fields.photos[i].objectURL) {
            try {
              const formData = new FormData()
              formData.append('photo', form.fields.photos[i])
              await addContentPhotoAction({
                    id: form.fields.id,
                    fields: formData,
                  }
              )
              if (contentAddPhotoData.value) {
                form.fields.photos[i] = contentAddPhotoData.value.data.photos[i]
              }
            } catch (e) {
              errors.value = {imageError: contentAddPhotoError.value.data, index: form.fields.photos[i].objectURL}
              console.error(e)
              return
            }
          }
        }
      }
      for (let i = 0; i < form.fields.advantages.length; i += 1) {
        if (!form.fields.advantages[i].id.toString().includes('newItem')) {
          const oldItem = contentData.value.data.advantages.findIndex(item => item.id === form.fields.advantages[i].id)
          if (contentData.value.data.advantages[oldItem] && (form.fields.advantages[i].photo.objectURL || contentData.value.data.advantages[oldItem].photo.id !== form.fields.advantages[i].photo.id || contentData.value.data.advantages[oldItem].text_ru !== form.fields.advantages[i].text_ru)) {
            const formData = new FormData()
            if (form.fields.advantages[i].photo.objectURL) {
              formData.append('photo', form.fields.advantages[i].photo)
            }
            formData.append('text_ru', form.fields.advantages[i].text_ru)
            try {
              await updateAdvantageAction({
                id: form.fields.id,
                advantageId: form.fields.advantages[i].id,
                fields: formData
              })
              if (contentUpdateAdvantageData.value) {
                contentData.value.data.advantages[oldItem] = {...contentUpdateAdvantageData.value.data}
                form.fields.advantages[i] = {...contentUpdateAdvantageData.value.data}
              }
            } catch (e) {
              errors.value = {item: form.fields.advantages[i], error: contentUpdateAdvantageError.value.data}
              console.error(e)
              return
            }
          }
        } else {
          const formData = new FormData()
          formData.append('text_ru', form.fields.advantages[i].text_ru)
          formData.append('photo', form.fields.advantages[i].photo)
          try {
            await addAdvantageAction({id: form.fields.id, fields: formData})
            if (contentAddAdvantageData.value?.data) {
              contentData.value.data.advantages.push(contentAddAdvantageData.value.data)
              form.fields.advantages[i] = {...contentAddAdvantageData.value.data}
            }
          } catch (e) {
            if (contentAddAdvantageError.value) {
              errors.value = {item: form.fields.advantages[i], error: contentAddAdvantageError.value.data}
            }
            console.error(e)
            return
          }
        }
      }
      if (!isError.value && !isPermanent.value) {
        await router.push({name: 'website', query: {tab: 'infrastructure'}})
      }
    }

    const changeVisibleModal = (index = null) => {
      visible.value = !visible.value
      if (index || index === 0) {
        deleteItem.value = index
      }
    };

    const addAdvantages = () => {
      form.fields.advantages.push({
        id: `newItem${form.fields.length + 1}`,
        text_ru: '',
        photo: null,
      })
    }

    const deleteHandler = async () => {
      if(deleteItem.value || deleteItem.value === 0) {
        if (!form.fields.advantages[deleteItem.value].id.toString().includes('newItem')) {
          try {
            await deleteAdvantageAction({
              id: isPermanent.value ? 'permanent' : form.fields.id,
              advantageId: form.fields.advantages[deleteItem.value].id
            })
          } catch (e) {
            console.error(e)
          }
        }
        if (contentDeleteAdvantageData.value || form.fields.advantages[deleteItem.value].id.toString().includes('newItem')) {
          form.fields.advantages.splice(deleteItem.value, 1);
          contentData.value.data.advantages.splice(deleteItem.value, 1);
          deleteItem.value = null
        }
        changeVisibleModal(null)
      } else {
        try {
          await deleteContentAction({
            id: form.fields.id,
          })
          if (contentDeleteData.value) {
            await router.push({name: 'website', query: {tab: 'infrastructure'}})
          }
        } catch (e) {
          console.error(e)
        }
      }
    }
    const children = ref([])
    const deleteCategoryId = ref(null)
    const closeDeleteCategory = () => {
      deleteCategoryId.value = null
      isDeleteCategory.value = false
    }
    const deleteCategory = (id) => {
      deleteCategoryId.value = id
      isDeleteCategory.value = true
    }
    const deleteCategoryHandler = async () => {
      try {
        await deleteContentAction({
          id: deleteCategoryId.value,
        })
        if (contentDeleteData.value) {
          children.value = children.value.filter(item => item.id !== deleteCategoryId.value)
        }
      } catch (e) {
        console.error(e)
      }
      closeDeleteCategory()
    }

    const goToCategory = async (id) => {
      await router.push({ name: 'apartments-category', params: { id: id } })
    }
    const isDeleteCategory = ref(false)
    const addCategory = () => {
      router.push({name: 'apartments-category-add'})
    }
    onBeforeMount(async () => {
      if (isPermanent.value || route.params.id) {
        try {
          await getContentAction({id: isPermanent.value ? 'permanent' : route.params.id})
          if (contentData.value) {
            children.value = [...contentData.value.data.children]
            form.fields.id = contentData.value.data.id
            form.fields.advantages = [...contentData.value.data.advantages]
            form.fields.title_ru = contentData.value.data.title_ru
            form.fields.description_ru = contentData.value.data.description_ru
            form.fields.photos = [...contentData.value.data.photos]
          }
        } catch (e) {
          console.error(e)
        }
      }
      breadcrumbs.value = [
        { label: 'Сайт / Инфраструктура', router: {name: 'website', query: {tab: 'infrastructure'}} },
        {label: contentData.value ? contentData.value.data.title_ru : 'Создание инфраструктуры'}
      ];
      isLoading.value = false
    });

    return {
      t,
      form,
      breadcrumbs,
      update,
      loadFileMemory,
      deletePhoto,
      v$,
      errors,
      isLoading,
      deleteItem,
      changeAdvantages,
      deleteHandler,
      changeVisibleModal,
      addAdvantages,
      visible,
      validError,
      route,
      isPermanent,
      isContentDeleteAdvantageLoading,
      isUpdateLoading,
      isContentDeleteLoading,
      children,
      deleteCategory,
      goToCategory,
      isDeleteCategory,
      closeDeleteCategory,
      deleteCategoryHandler,
      addCategory,
    };
  }
});
</script>

<template>
  <ConfirmationModal :visible="visible">
    <template #header>
      Удалить {{ deleteItem !== null ? t(`confirmationModal.infrastructureItem.advantages`) : t(`confirmationModal.infrastructureItem.title`) }}
    </template>

    <template #default>
      <span>Вы уверены,  что хотите удалить {{ deleteItem !== null ? t(`confirmationModal.infrastructureItem.advantages`) : t(`confirmationModal.infrastructureItem.title`) }}? Это действие будет невозможно отменить</span>
    </template>

    <template #footer>
      <div class="flex justify-content-between">
        <Button :label="t('labels.cancel')" @click="changeVisibleModal(null)" class="btn-primary-outlined font-light w-12"/>
        <Button :label="t('labels.destroy')" :loading="isContentDeleteAdvantageLoading || isContentDeleteLoading" @click="deleteHandler" class="btn-primary font-light ml-3 w-12"/>
      </div>
    </template>
  </ConfirmationModal>
  <ConfirmationModal :visible="isDeleteCategory">
    <template #header>
      Удалить категорию
    </template>

    <template #default>
      <span>Вы уверены,  что хотите удалить категорию? Это действие будет невозможно отменить</span>
    </template>

    <template #footer>
      <div class="flex justify-content-between">
        <Button :label="t('labels.cancel')" @click="closeDeleteCategory" class="btn-primary-outlined font-light w-12"/>
        <Button :label="t('labels.destroy')" :loading="isContentDeleteLoading" @click="deleteCategoryHandler" class="btn-primary font-light ml-3 w-12"/>
      </div>
    </template>
  </ConfirmationModal>

  <section class="py-2 mb-3">
    <div class="flex justify-content-between">
      <Breadcrumb v-if="!isPermanent" :data="breadcrumbs" separator="/"/>
      <div class="flex">
        <Button
            @click="update"
            :loading="isUpdateLoading"
            label="Сохранить изменения"
            :class="['btn-primary btn-fix-height font-light ml-3', {permanent: isPermanent}]"
        />
      </div>
    </div>
  </section>
  <section class="py-2 mb-3">
    <Skeleton v-if="isLoading" height="154px"></Skeleton>
    <MainCard v-else title="Заголовок" class="w-full">
            <span class="p-float-label mb-3 w-full">
              <InputText
                  :class="['w-full', {'p-invalid': v$.title_ru.$errors?.length || errors?.title_ru }]"
                  id="name_ru"
                  v-model="form.fields.title_ru"
              />
              <label for="name_ru">Введите заголовок <i style="color: #E26B6B">*</i></label>
            </span>
      <span v-if="v$.title_ru.$errors?.length || errors?.title_ru" class="color-error">{{ errors?.title_ru ? errors.title_ru[0] : t(v$.title_ru.$errors[0].$message) }}</span>
    </MainCard>
  </section>

  <section class="py-2 mb-3 apartments-category">
    <Skeleton v-if="isLoading" height="154px"></Skeleton>
    <span class="apartments-category__title">{{t('apartments.category.title')}}</span>
    <div class="apartments-category__list">
      <div v-for="item in children" @click="goToCategory(item.id)" :style="item.photos ?  {background: `url(${item.photos[0]?.preview_url})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' } : {}" class="apartments-category__item">
        <i @click.stop="deleteCategory(item.id)" class="pi pi-times text-white cansel-button cursor-pointer apartments-category__item-delete"></i>
        <span class="apartments-category__item-title">
          {{item.title_ru}}
          <img src="@/assets/images/icons/arrow-right.svg" alt="arrow-right" class="apartments-category__item-icon" />
        </span>
      </div>
      <WebsiteAddButton
          @clickHandler="addCategory"
          title="Добавить категорию"
      />
    </div>
  </section>


  <section class="py-2 mb-3">
    <MainCard title="Изображения слайдера">
      <div class="grid">
        <span v-if="v$.photos.$errors?.length" class="flex text-xs color-error ml-2 mb-2">
              {{ t(v$.photos.$errors[0].$message) }}
            </span>
        <template v-if="form.fields.photos.length">
          <div v-for="(file, i) in form.fields.photos" :key="i" class="col-12 md:col-2">
            <ImageCard :src="file.preview_url ? file.preview_url : file.objectURL" :handle="() => deletePhoto(file)"/>
            <span v-if="errors?.imageError?.photo.length && errors.index === file.objectURL" class="flex text-xs color-error mb-2">
              {{ errors.imageError.photo[0] }}
            </span>
          </div>
        </template>
        <div class="col-12">
          <ButtonFileUpload @chooseFiles="loadFileMemory" label="Добавить изображение" :multiple="true"
                            :clear-files-after-select="true"/>
        </div>
      </div>
    </MainCard>
  </section>
  <section class="py-2 mb-3">
    <MainCard title="Описание">
      <div class="grid">
        <div class="col-12">
          <Editor v-model="form.fields.description_ru" class="w-full"></Editor>
          <span v-if="v$.description_ru.$errors?.length || errors?.description_ru" class="color-error">{{ errors?.description_ru ? errors.description_ru[0] : t(v$.description_ru.$errors[0].$message) }}</span>
        </div>
      </div>
    </MainCard>
  </section>
  <section class="py-2 mb-3">
    <h1 class="mb-4">Преимущества</h1>
    <div v-if="form.fields.advantages.length" class="infrastructure__slides">
      <BrandCard
          v-for="(item, i) in form.fields.advantages"
          :key="item.id"
          scope="advantages"
          :slide="item"
          :index="i"
          :title="t(`websiteCard.advantages.title`)"
          :inputTitle="t(`websiteCard.advantages.input`)"
          :imgTitle="t(`websiteCard.advantages.image`)"
          :rows="4"
          isIcon
          @deleteSlide="changeVisibleModal"
          @changeSlide="changeAdvantages"
          :validError="validError"
          :errors="errors && errors.item === item ? errors.error : null"
      />
    </div>
    <Skeleton v-if="isLoading" height="121px"></Skeleton>
    <div class="infrastructure__slides mt-4">
      <WebsiteAddButton
          @clickHandler="addAdvantages"
          :title="`Добавить ${ t(`confirmationModal.infrastructureItem.advantages`) }`"
      />
    </div>
  </section>
  <section v-if="route.params.id && !isPermanent" class="py-2 mb-4">
    <div class="flex justify-content-end">
      <span class="color-error underline cursor-pointer" @click="changeVisibleModal(null)">
        Удалить
      </span>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.infrastructure {
  &__slides {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 30px;
    row-gap: 16px;
    word-break: break-all;
    @media screen and (min-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (min-width: 1400px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
.permanent {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.apartments-category {
  &__title {
    display: flex;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
  }
  &__list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    @media screen and (min-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (min-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  &__item {
    position: relative;
    display: flex;
    border-radius: 10px;
    box-shadow: inset 0 0 0 100vw rgba(0, 0, 0, 0.2);
    height: 325px;
    padding: 30px;
    cursor: pointer;
    word-break: break-all;
    &-delete {
      position: absolute;
      right: 22.5px;
      top: 22.5px;
      font-size: 14px;

    }
    &-title {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-top: auto;
      font-weight: 600;
      font-size: 30px;
      line-height: 100%;
      color: #FFFFFF;
    }
  }
}
</style>